<template>
  <div id="loading-wrapper" v-if="getPlatform() === 'total'">
    <div id="loading-text">
      <TotalLogoStamp
        :image="require('../../assets/img/PNG/total-express-logo.png')"
        height="101"
        width="auto"
      />
    </div>
  </div>
  <div id="loading-wrapper" v-else-if="getPlatform() === 'asia'">
    <div id="loading-text">
      <AsiaLogoStamp height="101" fill="#13105d" width="auto" />
    </div>
  </div>
  <div id="loading-wrapper" v-else-if="getPlatform() === 'e-cross'">
    <div
      id="loading-text"
      class="bg-primary p-2 border rounded"
      style="width: 220px; height: auto"
    >
      <img
        :src="require('../../assets/img/PNG/e-cross-logo-bg.png')"
        class="bg-header"
        height="50"
        width="auto"
      />
    </div>
  </div>
  <div id="loading-wrapper" v-else-if="getPlatform() === 'anjun'">
    <div id="loading-text" style="width: 220px; height: auto">
      <img
        :src="require('../../assets/img/PNG/anjun-logo.png')"
        class="bg-header"
        height="50"
        width="auto"
      />
    </div>
  </div>
  <div id="loading-wrapper" v-else-if="getPlatform() === 'tiendamia'">
    <div id="loading-text" style="width: 220px; height: auto">
      <img
        :src="require('../../assets/img/PNG/tiendamia-logo.png')"
        class="bg-header"
        height="50"
        width="auto"
      />
    </div>
  </div>
  <div id="loading-wrapper" v-else-if="getPlatform() === 'mirakl'">
    <div id="loading-text">
      <img
        :src="require('../../assets/img/PNG/mirakl-logo.png')"
        class="bg-header"
        height="auto"
        width="auto"
      />
    </div>
  </div>
  <div id="loading-wrapper" v-else-if="getPlatform() === 'skypostal'">
    <div id="loading-text">
      <img
        :src="require('../../assets/img/PNG/skypostal-logo.png')"
        class="bg-header"
        height="50"
        width="auto"
      />
    </div>
  </div>
  <div id="loading-wrapper" v-else-if="getPlatform() === 'jt'">
    <div id="loading-text">
      <img
        :src="require('../../assets/img/PNG/jt-logo.png')"
        class="bg-header"
        height="50"
        width="auto"
      />
    </div>
  </div>
  <div id="loading-wrapper" v-else-if="getPlatform() === 'correios'">
    <div id="loading-text">
      <img
        :src="require('../../assets/img/PNG/correios-logo.png')"
        class="bg-header"
        height="50"
        width="auto"
      />
    </div>
  </div>
  <div id="loading-wrapper" v-else-if="getPlatform() === 'shopee'">
    <div id="loading-text">
      <img
        :src="require('../../assets/img/PNG/shopee-logo.png')"
        class="bg-header shopee-logo-login"
        height="120"
        width="auto"
        style="background-color: #ef5436; padding: 0.3rem; border-radius: 5px"
      />
    </div>
  </div>
  <div v-else id="loading-wrapper">
    <div id="loading-text">
      <IconLogoStampCircle height="170" width="170" />
    </div>
    <div id="loading-content">
      <IconLogoStampDots />
    </div>
  </div>
</template>

<script>
import IconLogoStampCircle from "@/components/icons/IconLogoStampCircle.vue";
import IconLogoStampDots from "@/components/icons/IconLogoStampDots.vue";
import AsiaLogoStamp from "@/components/icons/AsiaLogoStamp.vue";
import TotalLogoStamp from "@/components/icons/TotalLogoStamp.vue";
export default {
  name: "LoadingPage",
  components: {
    IconLogoStampCircle,
    IconLogoStampDots,
    TotalLogoStamp,
    AsiaLogoStamp,
  },
  methods: {
    getPlatform() {
      return this.$helpers.getPlatform();
    },
  },
};
</script>

<style scoped>
#loading-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

#loading-text {
  z-index: 2;
  display: block;
  position: relative;
  top: calc(50% - 55px);
  left: calc(49.8% + 50px);
  color: rgb(20, 121, 60);
  width: 100px;
  height: 30px;
  margin: -7px 0 0 -45px;
  text-align: center;
  font-family: "PT Sans Narrow", sans-serif;
  font-size: 20px;
}

#loading-content {
  display: block;
  position: relative;
  left: calc(49.8% + 90px);
  top: 50%;
  width: 170px;
  height: 170px;
  margin: -85px 0 0 -85px;
  /* border: 3px solid #F00; */
}

#loading-content {
  /* border: 3px solid transparent;
    border-top-color: rgb(51, 177, 235);
    border-bottom-color: rgb(25, 145, 201);
    border-radius: 50%; */
  -webkit-animation: loader 2s linear infinite;
  -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
  animation: loader 2s linear infinite;
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
